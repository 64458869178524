import { styled, css } from "styled-components";
import theme from "styled-theming";

import { default as BaseDocument } from "components/Document";
import TextBlock from "components/TextBlock";
import { styleBodyM } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
    lightgray: "--color-white",
    blue: "--color-cyan-o20",
    cyan: "--color-blue-o15",
});

export const Container = styled.div`
    background: var(${backgroundColor});
    border-radius: 0.25rem;
    display: flex;
    flex-flow: column wrap;
    margin: 1.75rem 0;
    padding: 1.75rem;

    ${theme("blockInBlock", {
        true: css`
            margin-top: 0;
        `,
    })}

    ${MQ.FROM_M} {
        margin: 2.1875rem 0;
        ${theme("blockInBlock", {
            true: css`
                margin-top: 0;
            `,
        })}
    }

    ${MQ.FROM_XL} {
        margin: 2.625rem 0;
        ${theme("blockInBlock", {
            true: css`
                margin-top: 0;
            `,
        })}
    }
`;

export const Heading = styled.div.attrs<{
    $hasIcon?: boolean;
    $iconColor?: string;
}>(() => ({}))`
    ${styleBodyM};
    font-weight: bold;
    grid-column: col-start 1 / span 4;
    margin-bottom: 1.75rem;
    margin-top: 0;
    ${(props) =>
        props.$hasIcon &&
        css`
            padding-left: 2.625rem;
            position: relative;

            &::before {
                background-color: ${props.$iconColor
                    ? "var(--color-traffic-" + props.$iconColor + ")"
                    : "var(--color-traffic-green)"};
                border-radius: 50%;
                content: "";
                display: block;
                height: 1.3125rem;
                inset: 3px;
                position: absolute;
                width: 1.3125rem;
            }
        `}
`;

export const StyledTextBlock = styled(TextBlock)`
    margin-bottom: 1.75rem;

    div {
        &:first-child :first-child {
            margin-top: 0;
        }

        &:last-child :last-child {
            margin-bottom: 0;
        }
    }
`;

export const Documents = styled.div`
    display: flex;
    flex-flow: column wrap;
    gap: 0.875rem;
`;

export const Document = styled(BaseDocument)`
    display: block;
    min-height: 1.75rem;
    padding-left: 2.1875rem;
    position: relative;

    span {
        ${styleBodyM};
    }

    svg {
        left: 0;
        position: absolute;
        top: 0;
    }

    ${theme("theme", {
        blue: css`
            color: var(--color-cyan-l60);

            span {
                border-color: var(--color-cyan-l60-o30);
            }

            &:hover {
                span {
                    border-color: var(--color-cyan-l60);
                }
            }
        `,
    })}
`;
